//@flow
import * as React from 'react';
import css from "./itinerary.module.scss";

type Props = {
  k: string,
  v: string | React$Element < * >
}

class InfoItem extends React.Component < Props > {
  render() {
    return <div className={css.infoItem}>
      <span className={css.key}>{this.props.k}</span>
      <span className={css.value}>{this.props.v}</span>
    </div>
  }
}

export default InfoItem;