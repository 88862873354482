//@flow
import * as React from 'react';
import Itinerary from './itinerary/itineraryContainer';

class Dashboard extends React.Component < * > {


  render() {
    return <div>
    <Itinerary {...this.props}/>

    </div>;
  }
}

export default Dashboard;