//@flow
import {
  // getPrettyDateTime,
  formatPostcode,
  // formatPhonenumber,
  // getPrettyDate
} from '../../lib/Helpers';
import * as React from 'react';
import {
  // ContactPanel,
  // ContactPanelItem
} from './contact-panel';
import InfoItem from './info-item';
import css from "./itinerary.module.scss";
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
// import Panel from 'lib/panel/panel';
import Collapsible from 'react-collapsible';




type MapPanelProps = {
  location: Object
}
type MapPanelState = {
  showMap: bool
}
class MapPanel extends React.Component<MapPanelProps, MapPanelState> {
  API_KEY = "AIzaSyDqrGe5JnwC3BDFx-zkSn7n8rVU77Ywueo";

  state = {
    showMap: false
  }

  toggleMap() {
    this.setState({
      showMap: !this.state.showMap
    })
  }

  render() {
    const lat = this.props.location.latitude;
    const long = this.props.location.longitude;
    const postcode = this.props.location.postcode;

    let map = null;
    if (lat && long) {
      map = <iframe
        className={css.locationFrame}
        title="Location Map"
        frameBorder="0" style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${lat},${long}`} allowFullScreen>
      </iframe>
    } else if (postcode) {
      map = <iframe
        className={css.locationFrame}
        title="Location Map"
        frameBorder="0" style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${postcode}`} allowFullScreen>
      </iframe>
    } else {
      map = <div className={css.locationFrame}>Provide a postcode to display the map.</div>;
    }

    return <div>
      <InfoItem k="Postcode" v={<span className={css.link} onClick={() => this.toggleMap()}>{postcode && formatPostcode(postcode)} <FontAwesomeIcon icon="map-marker-alt" /></span>} />
      <Collapsible open={this.state.showMap} lazyRender={true} transitionTime={200}>
        {map}
      </Collapsible>
    </div>
  }
}
export default MapPanel;