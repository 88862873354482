// @flow
import * as React from "react";
// import ReactDOM from 'react-dom';
import Logo from "./logo";
import MainFooter from "../lib/mainfooter/MainFooter";
import Dashboard from "../components/dashboard";
import { ERR404 } from "../lib/404";

import ErrorBoundary from "../lib/error-boundary";

import { Route, Switch, Redirect } from "react-router-dom";

class App extends React.Component<*> {
  render() {
    return (
      <div>
        <div className="app">
          {/* <ErrorBoundary> */}
          {/* <Route path="/" children={(rest) => <MainMenu {...rest}/>}/> */}
          {/* <Route path="/" children={(rest) => <Infobar {...rest}/>}/> */}
          {/* </ErrorBoundary> */}
          <ErrorBoundary>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="*" component={ERR404} />
            </Switch>
          </ErrorBoundary>
          <MainFooter />
        </div>
        <Logo />
        <div
        style={{with:"100%",textAlign:'center',fontWeight:'900',cursor:'pointer'}}
          onClick={() => {
            window.location.reload(true);
          }}
        >
          If this page has failed to load please <u>CLICK HERE</u> to attempt to reload it
        </div>
      </div>
    );
  }
}
export default App;
