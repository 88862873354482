// @flow
import React from 'react';
type State = {
  hasError: boolean
}
class ErrorBoundary extends React.Component < * , State > {
  constructor(props: Object) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Object, info: Object) {
    // Display fallback UI
    this.setState({
      hasError: true
    });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      const wrapperstyle = {
        width: "100%",
        maxWidth: "400px",
        margin: "10px auto"
      }
      const style = {
        width: "100%"
      }
      return <div style={wrapperstyle}>
          <img src="/img/404.png" alt="Oops. Nothing to see here (404)" style={style}/>
        </div>
    }
    return this.props.children;
  }
}
export default ErrorBoundary;