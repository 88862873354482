//@flow
import {
  getPrettyDateTime,
  // formatPostcode,
  formatPhonenumber,
  // getPrettyDuration,
  getPrettyDate,
  getPrettyTime,
  getPrettyDistanceFromMetres,
  // getPrettyDurationFromMins,
  getPrettyDurationFromSecs
} from '../../lib/Helpers';
import Panel from '../../lib/panel/panel';
import * as React from 'react';
import {
  // ContactPanel,
  // ContactPanelItem
} from './contact-panel';
import InfoItem from './info-item';
import css from "./itinerary.module.scss";
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
// import moment from 'moment';
import MapPanel from './map-panel';

type DriverProps = {
  d: Object
}
class Driver extends React.Component<DriverProps> {
  render() {
    const d = this.props.d;
    if (!d) return null;
    return <div className={css.driverWrapper}>
      <div className={css.driverPic}>
        {d.profilePicture ? <img className={css.profilePicture} src={d.profilePicture} alt="profile"></img> : null}
      </div>
      <div className={css.driverInfo}>
        <div className={css.driverhead}><b>Driver</b></div>
        <div className={css.driverName}>{d.name}</div>
        <div>{formatPhonenumber(d.phoneMobile)} <em>(call / whatsapp)</em></div>
      </div>
    </div>
  }
}


type PickupProps = {
  p: Object,
  leadartist: Object
}
class Pickup extends React.Component<PickupProps> {

  render() {
    const pickup = this.props.p.node;
    const shift = pickup.job && pickup.job.shift;
    const driver = shift && shift.gtDriver && shift.gtDriver.person;
    const vehicle = shift && shift.gtVehicle;
    // const journey = pickup && pickup.journey;
    // const journeyDistance = journey && (journey.distance / 1000) + "km";
    // const journeyTime = journey && (getPrettyDuration(moment.duration(journey.time, 'seconds')));

    const j = shift ? <span>
      <em>
        <InfoItem k="" v={pickup.job.locked ?
          <div className={css.confirmed}><FontAwesomeIcon icon="check" /> Driver & Vehicle confirmed</div>
          : <div className={css.warning}>
            <FontAwesomeIcon icon="exclamation-triangle" /> Driver / Vehicle still subject to change</div>} />
      </em>
      {driver ? <Driver d={driver} /> : null}
      {vehicle ? <span className={css.vehicle}>
        <InfoItem k="Vehicle Make" v={vehicle && vehicle.make} />
        <InfoItem k="Vehicle Model" v={vehicle && vehicle.model} />
        {/* <InfoItem k="Vehicle Reg" v={vehicle && vehicle.reg}/> */}
      </span> : null}
    </span> :
      <div className={css.warning}>
        <FontAwesomeIcon icon="exclamation-triangle" /> Driver / Vehicle not assigned yet
        </div>;

    const preview = <div><b>{pickup.from.name} <FontAwesomeIcon icon="arrow-right" /> {pickup.to.name}</b></div>;

    return <div className={css.halfWidth} key={pickup.id}>
      <Panel preview={preview}
        topLeft={getPrettyDate(pickup.at)}
        topRight={getPrettyTime(pickup.at)}
        showDetailInit={false}>
        <div className={css.infoItemWrapper}>
          <InfoItem k="Pickup time" v={getPrettyDateTime(pickup.at)} />
          <InfoItem k="Pickup Location" v={pickup.from.name} />
          <MapPanel location={pickup.from} />
          <InfoItem k="Pickup name" v={pickup.pickupName ? pickup.pickupName : this.props.leadartist && this.props.leadartist.name} />
          <InfoItem k="Number of passengers" v={pickup.numberOfPassengers} />
          <InfoItem k="Journey" v={getPrettyDistanceFromMetres(pickup?.journey?.distance) + " / ~" + getPrettyDurationFromSecs(pickup?.journey?.time)} />
          <InfoItem k="Additional notes" v={pickup.notes} />
          {j}
        </div>
      </Panel>
    </div>;


    // return <div className={css.pickupWrapper} key={pickup.id}>
    //   <div>{getPrettyDate(pickup.at)}</div>
    //   <div className={css.flexRow}>
    //     <div>Pickup @ {getPrettyTime(pickup.at)}</div>
    //   </div>
    //   Est. time in traffic {journeyTime}
    //

    // </div>
  }
}

type Props = {
  pickups: Object,
  leadartist: Object
}
class Transport extends React.Component<Props> {
  API_KEY = "AIzaSyDqrGe5JnwC3BDFx-zkSn7n8rVU77Ywueo";

  render() {
    const pickups = this.props.pickups.edges;

    // let map = null;
    // if (v && v.latitude && v.longitude) {
    //   map = <iframe
    //     className={css.locationFrame}
    //     title="Location Map"
    //     frameBorder="0" style={{border:0}}
    //     src={`https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${v.latitude},${v.longitude}`} allowFullScreen>
    //   </iframe>
    // } else if (v && v.postcode) {
    //   map = <iframe
    //     className={css.locationFrame}
    //     title="Location Map"
    //     frameBorder="0" style={{border:0}}
    //     src={`https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${v.postcode}`} allowFullScreen>
    //   </iframe>
    // } else {
    //   map = <div className={css.locationFrame}>Provide a postcode to display the map.</div>;
    // }


    if (!pickups) return <div>none</div>

    const arr = pickups.map((p) => {
      return <Pickup p={p} key={p.node.id} leadartist={this.props.leadartist} />
    })
    return <div className={css.section}>
      <div className={css.transportBrief}>Shuttles run to/from <em>The Village Hotel</em> all day. On the day, please contact your driver if you want to change your pickup times.</div>
      {arr}
    </div>;
  }
}

export default Transport;