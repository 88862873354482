//@flow
import * as React from "react";
import fluxconvert from "./../../app/fluxconvert";

import { Container } from "flux/utils";
import Store from "./../../data/store";
//react-relay
import {
  QueryRenderer,
  // graphql
} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import env from "../../app/relay/environment";

import Itinerary from "./itinerary";
import Loading from "../../lib/Loading";
import { findGetParameter } from "../../lib/Helpers";
const rootQuery = graphql`
  query itineraryContainer_GetData_Query($token: String) {
    public(token: $token) {
      id
      booking {
        id
        title
        qrIdString
        eventalArtistPerformances {
          edges {
            performance {
              id
              start
              duration
              onBefore {
                artists {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
              onAfter {
                artists {
                  edges {
                    node {
                      name
                    }
                  }
                }
              }
              artists {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              timeline {
                id
                room {
                  id
                  name
                  manager {
                    id
                    name
                    phoneMobile
                    email
                    profilePicture
                  }
                  event {
                    id
                    name
                    artistBriefing
                    productionManager {
                      id
                      name
                      phoneMobile
                      email
                      profilePicture
                    }
                    transportManager {
                      id
                      name
                      phoneMobile
                      email
                      profilePicture
                    }
                    locations {
                      edges {
                        node {
                          id
                          name
                          address
                          postcode
                          email
                          phone
                          latitude
                          longitude
                          what3words
                        }
                      }
                    }
                  }
                }
              }
            }
            artist: node {
              id
              name
            }
          }
        }
        reservations {
          edges {
            node {
              id
              checkIn
              checkOut
              nights
              reservationNumber
              roomType
              bookingName
              notes
              location {
                name
                address
                postcode
                latitude
                longitude
                phone
                email
                id
              }
            }
          }
        }
        pickups {
          edges {
            node {
              id
              from {
                id
                name
                postcode
                latitude
                longitude
              }
              to {
                id
                name
                postcode
                latitude
                longitude
              }
              at
              pickupName
              notes
              numberOfPassengers
              job {
                id
                locked
                shift {
                  gtDriver {
                    id
                    person {
                      id
                      name
                      phoneMobile
                      profilePicture
                    }
                  }
                  gtVehicle {
                    make
                    model
                    reg
                  }
                }
              }
              journey {
                id
                distance
                time
              }
            }
          }
        }
      }
    }
  }
`;

type State = {
  Store: Object,
};

class ItineraryContainer extends React.Component<*, State> {
  static getStores() {
    return [Store];
  }

  static calculateState(prevState) {
    return {
      Store: Store.getState(),
    };
  }

  render() {
    // invariant(this.state, "no state")
    if (this.state.Store.get("loading") === true) return <Loading />;

    return (
      <QueryRenderer
        environment={env}
        query={rootQuery}
        variables={{ token: btoa(atob(findGetParameter("at"))) }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return <Itinerary data={props} {...this.props} />;
          } else {
            return <Loading />;
          }
        }}
      />
    );
  }
}

export default Container.create(fluxconvert(ItineraryContainer));
