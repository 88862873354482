//@flow
import {
  getPrettyDateTime,
  // formatPostcode,
  formatPhonenumber,
  getPrettyDate
} from '../../lib/Helpers';
import * as React from 'react';
import {
  // ContactPanel,
  // ContactPanelItem
} from './contact-panel';
import InfoItem from './info-item';
import css from "./itinerary.module.scss";
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import Panel from '../../lib/panel/panel';
// import Collapsible from 'react-collapsible';
import MapPanel from './map-panel';


type Props = {
  reservations: Array<Object>,
  artist: Object
}
class Reservation extends React.Component<Props> {


  render() {


    const rs = this.props.reservations;
    if (!rs) return <div>none</div>

    // let mapShown = false;

    let rArry =
      rs.map((edge) => {
        const r = edge.node;
        const v = r && r.location;

        const preview = <div>
          <div className={css.blue}>{r.roomType.toLowerCase()} room for {r.nights} night{r.nights !== 1 ? "s" : null}</div>
          <div><b>{v.name}</b></div>
        </div>;

        let topRight = r.bookingName ? r.bookingName : this.props.artist.name;
        //
        // if (r.reservationNumber)
        //   topRight = topRight + " / " + r.reservationNumber;

        return <div className={css.halfWidth} key={edge.node.id}>
          <Panel
            preview={preview}
            topLeft={getPrettyDate(r.checkIn)}
            topRight={topRight}
            showDetailInit={false}>
            <div className={css.infoItemWrapper}>
              <InfoItem k="Reservation Number" v={r.reservationNumber ? r.reservationNumber : "---"} />
              <InfoItem k="Name on Booking" v={r.bookingName ? r.bookingName : this.props.artist.name} />
              <InfoItem k="Check In From" v={getPrettyDateTime(r.checkIn)} />
              <InfoItem k="Check Out By" v={getPrettyDateTime(r.checkOut)} />

              <InfoItem k="Address" v={v.address} />
              <MapPanel location={v} />
              <InfoItem k="Phone" v={v.phone ? formatPhonenumber(v.phone) : "n/a"} />
              <InfoItem k="Notes" v={r.notes} />
              <div className={css.warning}>
                <FontAwesomeIcon icon="exclamation-triangle" /> This is the number for reception - NOT a primary contact
            </div>
              {/* <InfoItem k="Distance to venue" v="---"/> */}
            </div>
          </Panel>
        </div>;
      });

    return <div className={css.section}>{rArry}</div>;

  }
}

export default Reservation;