//@flow
import {
  findGetParameter
} from '../../lib/Helpers';

const {
  Network
} = require('relay-runtime');

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(
  operation,
  variables,
  cacheConfig,
  uploadables,
) {

  let at = findGetParameter("at");
  const ats = findGetParameter("ats")

  at =btoa( atob(at))// solves issue of = sign in the base64 string

  if (!at || !ats) { console.log("no access"); return; }
  // const at = "1112000000000070"; //test booking
  // const t_at = btoa(at);
  // const t_ats = t_at;

  // console.log(process.env)
  const protocol = process.env.REACT_APP_USE_SSL === 'yes' ? 'https://' : 'http://';
  const host = process.env.REACT_APP_GRAPHQL_HOST ? process.env.REACT_APP_GRAPHQL_HOST : "";
  const port = process.env.REACT_APP_GRAPHQL_PORT ? process.env.REACT_APP_GRAPHQL_PORT : 4000;
  const rooturl = process.env.REACT_APP_DEV_MODE === 'true' ? protocol + host + ":" + port + "/" : protocol + host + "/"
  const url = rooturl + `?at=${at}&ats=${ats}`;
  // const url = rooturl+"";
  console.log("API url",rooturl)
  return fetch(url, {
    method: 'POST',
    headers: {
      // Add authentication and other headers here
      'content-type': 'application/json',
      'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiIyODE0NzQ5ODAwMDkwMDAzIiwiaXNBZG1pbiI6dHJ1ZX0.3aDoUz7gDxtZ3MitPWcTS7oKmKMt_yOzSA-pZZspvtg`,
      // 'X-api-key': "0kmNYhxHPP9YCscUNiuGv53oyoOlfDPmaYjL1BfI"
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  }).then(response => {
    return response.json();
  });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);

export default network;