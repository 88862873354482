//@flow
import {
  OrderedMap
} from 'immutable';

import {
  ReduceStore
} from 'flux/utils';

import Dispatcher from './../app/Dispatcher';
import {
  // ActionTypes
} from './actions';

class Store extends ReduceStore<Object> {
  constructor() {
    super(Dispatcher);
  }

  getInitialState(): Object {
    return OrderedMap();
  }

  reduce(state: Object, action: Object): Object {
    switch (action.type) {

      // case ActionTypes.SET_LOADING:
      //   return state.set("loading", action.value);

      default: return state;
    }
  }
}

export default new Store();