//@flow
import * as React from 'react';
import css from './panel.module.scss';
import {
  FontAwesomeIcon as FontAwesome
} from '@fortawesome/react-fontawesome';
import Collapsible from 'react-collapsible';

type Props = {
  children ? : React$Element < * > ,
  preview ? : React$Element < * > | string,
  topLeft ? : React$Element < * > | string,
  topRight ? : React$Element < * > | string,
  showDetailInit: bool
}
type State = {
  showDetail: bool,
}

class Panel extends React.Component < Props, State > {

  static defaultProps = {
    showDetailInit: false
  }

  state = {
    showDetail: this.props.showDetailInit
  }

  toggleShowDetail() {
    // console.log(!this.state.showDetail)
    this.setState({
      showDetail: !this.state.showDetail
    })
  }

  render() {
    return <div className={css.panel}>
      <div className={css.previewWrapper}  onClick={()=>this.toggleShowDetail()}>
        <div className={css.topRow}>
          <div className={css.topLeft}>{this.props.topLeft}</div>
          <div className={css.topRight}>{this.props.topRight}</div>
        </div>
        <div className={css.preview}>{this.props.preview}</div>
        <div className={css.detailToggle}>
          <div>DETAILS <FontAwesome icon={this.state.showDetail ? "chevron-circle-down" : "chevron-circle-left"}/></div>
        </div>
      </div>
      <Collapsible  open={this.state.showDetail}  lazyRender={false} transitionTime={200}>
        <div className={css.detail}>{this.props.children}</div>
      </Collapsible>
    </div>;
  }
}

export default Panel