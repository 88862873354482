//@flow
import * as React from 'react';
import css from './qrcode.module.scss';
import QRCode from '../../lib/qrcode/index.js';

type Props = {
  qrstring: string,
  eventName: string
}

export default class QRCodePanel extends React.Component<Props> {

  _ref: *;

  _download() {
    this._ref && this._ref.download(`${this.props.eventName} Entry QR Code.png`, "image/png");
  }

  render() {
    const { qrstring } = this.props;
    if (!qrstring) return null;

    var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    return <div className={css.section}>
      <div className={css.wrapper}>
        <div className={css.info}>This is your personal ID code. You and your guests will need it to confirm your ID when collecting your passes from the accreditation office.</div>
        <div className={css.col}>
          <QRCode
            value={qrstring}
            className={css.qrcode}
            level="L"
            size={300}
            renderAs="svg"
            ref={ref => this._ref = ref}
            img={null}
            includeMargin={true}
          />{isMac ?
            <div className={css.downloadButton} onClick={() => this._download()}>DOWNLOAD</div> : null}
        </div>
      </div>
    </div>
  }
}