// @flow
import * as React from 'react';

export class ERR404 extends React.Component < * > {
  render() {
    const wrapperstyle = {
      width: "100%",
      maxWidth: "400px",
      margin: "10px auto"
    }
    const style = {
      width: "100%"
    }
    return <div style={wrapperstyle}>
      <img src="/img/404.png" alt="Oops. Nothing to see here (404)" style={style}/>
    </div>
  }
}
export class ERR500 extends React.Component < * > {
  render() {
    const wrapperstyle = {
      width: "100%",
      maxWidth: "400px",
      margin: "10px auto"
    }
    const style = {
      width: "100%"
    }
    return <div style={wrapperstyle}>
      <img src="/img/500.png" alt="Fail (500)" style={style}/>
    </div>
  }
}