//@flow
import * as React from "react";
import // Link,
// BrowserHistory
"react-router";
import Briefing from "./briefing";
import {
  // ContactPanel,
  ContactPanelItem,
} from "./contact-panel";
import css from "./itinerary.module.scss";
import Performance from "./performance";
import Reservation from "./reservation";
import Transport from "./transport";
import Venue from "./venue";
import QRCode from "./qrcode";
import { FontAwesomeIcon as FontAwesome } from "@fortawesome/react-fontawesome";
import Collapsible from "react-collapsible";
import moment from "moment";

type Props = {
  data: Object,
  location: Object,
};
type State = {
  showSummary: boolean,
  showPerformance: boolean,
  showVenue: boolean,
  showAccomodation: boolean,
  showTransport: boolean,
  showBookingAgent: boolean,
  showContact: boolean,
  showBriefing: boolean,
  showQRCode: boolean,
  showWarning: boolean,
  showParking: boolean,
};
class Itinerary extends React.Component<Props, State> {
  state = {
    showSummary: true,
    showPerformance: true,
    showVenue: false,
    showAccomodation: false,
    showTransport: false,
    showBookingAgent: false,
    showContact: false,
    showBriefing: false,
    showQRCode: true,
    showWarning: true,
    showParking: true,
  };

  componentDidMount() {
    const booking = this.props?.data.public.booking;
    const shows = booking && booking.eventalArtistPerformances.edges;
    const firstEvent =
      shows && shows[0] && shows[0].performance.timeline.room.event;
    if (firstEvent)
      document.title = firstEvent && "Itinerary | " + firstEvent.name;
  }

  handleToggleSection(sectionName: string): void {
    const key = "show" + sectionName;
    this.setState({
      [key]: !this.state[key],
    });
  }

  sectionHeader = (name: string, title?: string, description?: string) => (
    <div
      className={css.section_header}
      onClick={() => this.handleToggleSection(name)}
    >
      <div>
        <div className={css.section_header_title}>
          {title ? title : `${name} Details`}
        </div>
        {description ? (
          <div className={css.section_header_description}>{description}</div>
        ) : null}
      </div>
      <div className={css.toggleButton}>
        <span>{this.state["show" + name] ? "hide" : "show"}</span>{" "}
        <FontAwesome
          icon={
            this.state["show" + name]
              ? "chevron-circle-down"
              : "chevron-circle-left"
          }
        />
      </div>
    </div>
  );

  render() {
    const booking = this.props?.data.public.booking;
    let shows = booking && booking.eventalArtistPerformances.edges;
    shows = shows && [...shows];
    shows &&
      shows.sort((a, b) => {
        return moment(a.performance.start) > moment(b.performance.start);
      });

    let atemp = [];
    const artists =
      shows &&
      shows.map((edge) => {
        if (atemp.includes(edge.artist.id)) return null;
        atemp.push(edge.artist.id);
        return (
          <div key={edge.artist.id}>
            {edge.artist.name} @ {edge.performance.timeline.room.event.name}
          </div>
        );
      });
    const reservations = booking && booking.reservations.edges;
    const firstRoom = shows && shows[0] && shows[0].performance.timeline.room;
    const firstEvent =
      shows && shows[0] && shows[0].performance.timeline.room.event;

    let perfIds = [];
    const perfArray =
      shows &&
      shows.map((show, i) => {
        if (perfIds.includes(show.performance.id)) return null;
        perfIds.push(show.performance.id);
        return <Performance key={i} performance={show.performance} />;
      });

    // console.log(shows);
    const qrcode = shows?.length > 0 && booking && (
      <span>
        <Collapsible
          trigger={this.sectionHeader(
            "QRCode",
            "Entry Code",
            "You will need this to get into the event"
          )}
          open={this.state.showQRCode}
          lazyRender={true}
          transitionTime={200}
        >
          <QRCode qrstring={booking.qrIdString} eventName={firstEvent.name} />
        </Collapsible>
        <hr />
      </span>
    );

    const r =
      reservations && reservations[0] ? (
        <span>
          <Collapsible
            trigger={this.sectionHeader(
              "Accomodation",
              "Accomodation",
              "Hotel & booking details"
            )}
            open={this.state.showAccomodation}
            lazyRender={true}
            transitionTime={200}
          >
            <Reservation
              reservations={reservations}
              artist={shows[0] && shows[0].artist}
            />
          </Collapsible>
          <hr />
        </span>
      ) : null;

    const t = booking?.pickups?.edges[0] ? (
      <span>
        <Collapsible
          trigger={this.sectionHeader(
            "Transport",
            "Transport",
            "Drivers, pickup times and locations"
          )}
          open={this.state.showTransport}
          lazyRender={true}
          transitionTime={200}
        >
          <Transport
            pickups={booking.pickups}
            leadartist={shows[0] && shows[0].artist}
          />
        </Collapsible>
        <hr />
      </span>
    ) : null;

    const briefing =
      firstEvent && firstEvent.artistBriefing ? (
        <span>
          <Collapsible
            trigger={this.sectionHeader(
              "Briefing",
              "Arrival Details, Parking , Guests, Payment etc",
              "IMPORTANT information and FAQs"
            )}
            open={this.state.showBriefing}
            lazyRender={true}
            transitionTime={200}
          >
            <Briefing html={firstEvent.artistBriefing} shows={shows} />
          </Collapsible>
          <hr />
        </span>
      ) : null;

    const venue =
      shows && shows[0] ? (
        <span>
          <Collapsible
            trigger={this.sectionHeader(
              "Venue",
              "Venue",
              "Venue details for the show"
            )}
            open={this.state.showVenue}
            lazyRender={true}
            transitionTime={200}
          >
            <Venue
              venue={
                shows &&
                shows[0] &&
                shows[0].performance.timeline.room.event.locations.edges[0] &&
                shows[0].performance.timeline.room.event.locations.edges[0].node
              }
            />
          </Collapsible>{" "}
          <hr />
        </span>
      ) : null;

    const performance =
      shows && shows[0] ? (
        <span>
          <Collapsible
            trigger={this.sectionHeader(
              "Performance",
              "Performance",
              "Stage time, set duration etc"
            )}
            open={this.state.showPerformance}
            lazyRender={true}
            transitionTime={200}
          >
            <div className={css.section}>{perfArray}</div>
          </Collapsible>
          <hr />
        </span>
      ) : null;

    const contacts =
      shows && shows[0] ? (
        <span>
          <Collapsible
            trigger={this.sectionHeader(
              "Contact",
              "Contact Info",
              "Your point of contact with us"
            )}
            open={this.state.showContact}
            lazyRender={true}
            transitionTime={200}
          >
            {booking && booking.pickups.edges[0] ? (
              <div>
                For individual driver contact details please see{" "}
                <b>
                  <em>transport</em>
                </b>{" "}
                section above
              </div>
            ) : null}

            <div className={css.section}>
              <div className={css.column}>
                <div>
                  <b>PRIMARY CONTACT ON THE DAY</b>
                </div>
                <ContactPanelItem
                  person={firstRoom && firstRoom.manager}
                  role={firstRoom && firstRoom.name + " Stage Manager"}
                />
              </div>
              <div className={css.column}>
                <div>
                  <b>PRIMARY CONTACT BEFORE THE DAY</b>
                </div>

                <ContactPanelItem
                  person={firstEvent && firstEvent.productionManager}
                  // eslint-disable-next-line
                  role="Event Production Manager"
                />
              </div>
              {booking && booking.pickups.edges[0] ? (
                <div className={css.column}>
                  <ContactPanelItem
                    person={firstEvent && firstEvent.transportManager}
                    // eslint-disable-next-line
                    role="Event Transport Manager"
                  />
                </div>
              ) : null}
            </div>
          </Collapsible>
        </span>
      ) : null;

    const covid = (
      <span>
        <Collapsible
          trigger={this.sectionHeader(
            "Parking",
            "IMPORTANT - CAR PARK CHANGES",
            "Artist parking is now off-site"
          )}
          open={this.state.showParking}
          transitionTime={200}
        >
          <br />
          <p>
            <b>
              Artist car parking is now OFF-SITE. Do <u>NOT</u> try and drive
              your car to the festival site, you will be turned away.
            </b>
          </p>

          <p style={{ textAlign: "center" }}>
            Artist parking is now located at:
            <br />
            <b>
              St Helens Junction Station
              <br />
              Station Road
              <br />
              St Helens
              <br />
              WA9 3LA
            </b>
          </p>

          <h5>Parking FAQs</h5>
          <ul>
            <li>The car park is free, stewarded, and open 24/7</li>
            <li>The car park DOES NOT REQUIRE A PASS</li>
            <li>The car park is about a mile away from the festival</li>
            <li>
              There are shuttle busses running to and from the festival site
              from 9am until midnight
            </li>
          </ul>
        </Collapsible>

        <hr />
      </span>
    );

    // console.log(booking);
    return (
      <div className={css.wrapper}>
        <div className={css.bookingId}>
          <div>Booking #{booking?.id?.substr(-5)}</div>
          {/* <div>Booking #{booking && atob(booking.id).split(":")[1].substr(-5)}</div> */}
          <div>
            <a href="https://indemand.digital">Powered by In Demand Digital</a>
          </div>
        </div>
        <div className={css.title}>
          {shows && shows[0] ? "Artist" : "Booking"} Itinerary
        </div>
        <h3 className={css.title_artists}>
          {artists}
          {booking?.title}
        </h3>
        <div className={css.detailCheck}>
          <FontAwesome icon="exclamation-triangle" />
          This itinerary should contain the details of your performance and the
          venue, your contacts on the day, plus any accomodation or ground
          transport associated with your show. Please check all details
          carefully. Let us know as soon as possible if there are any mistakes
          or omissions.
        </div>
        <hr />
        {covid}
        {performance}
        {qrcode}
        {venue}
        {r}
        {t}
        {briefing}
        {contacts}
      </div>
    );
  }
}

export default Itinerary;
