//@flow
import * as React from 'react';
// import css from './contact-panel.css';
import css from "./itinerary.module.scss";

import {
  formatPhonenumber
} from '../../lib/Helpers';
type ItemProps = {
  person: Object,
  role: string,
}

export class ContactPanelItem extends React.Component<ItemProps> {
  render() {
    const person = this.props.person;
    if (!person) return null;
    return <span>
      {/* REMOVED FOR NON FESTIVAL EVENTS */}
      {/* <div><em>{this.props.role}</em></div> */}
      <div className={css.contactWrapper}>
        {person.profilePicture ? <div className={css.contactPic}>
          <img className={css.profilePicture} src={person.profilePicture} alt="profile pic"></img>
        </div> : null}
        <div className={css.contactInfo}>
          <div className={css.contactName}>{person.name}</div>
          <div className={css.contactPhone}>{formatPhonenumber(person.phoneMobile)}</div>
          <div className={css.contactEmail}><a href={`mailto:${person.email}`}>{person.email}</a></div>
        </div>
      </div>
    </span>

  }
}

type Props = {
  // title: string,
  children: Array<*>
}
export class ContactPanel extends React.Component<Props> {
  render() {
    return <div className={css.column}>
      {this.props.children}
    </div>
  }
}