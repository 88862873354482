//@flow
import * as React from "react";
import css from "./itinerary.module.scss";
import { getRaw } from "../../lib/Helpers";

type Props = {
  html: string,
  shows: Array<Object>,
};

export class Briefing extends React.Component<Props> {
  render() {
    // const links = this.props.shows.map((s) => {
    //   const linkdata = {
    //     Owner: s.artist?.name,
    //     Role: "ARTIST",
    //     RoleDesc: s.performance?.timeline?.room?.name,
    //     PassName: "Artist Car Park",
    //     PassDesc: "Marshalls Cross Road ///customers.reactions.smart",
    //   };

    //   const linkdataEnc = btoa(JSON.stringify(linkdata));
    //   const passlink = "https://artist.indemandartists.com/parkingpass?data=" + linkdataEnc;
    //   console.log(s);
    //   return (
    //     <a  download={`${s.performance?.timeline?.room?.event?.name} - ${s.artist?.name} PARKING PASS`} href={passlink} key={s.artist.id} target="_blank" rel="noreferrer">
    //       Download {s.artist.name}'s parking pass
    //     </a>
    //   );
    // });

    return (
      <div>
        <div className={css.briefing}>
          <div>
            <h6>Car Parking</h6>
            <div>
              If you're driving to the event, please park at ST HELENS JUNCTION STATION CAR PARK, WA9 3LA (See above for details). Shuttle bus provided. No parking pass is required.
            </div>
            {/* <div className={css.passLinks}>{links}</div> */}
          </div>
        </div>
        <div
          className={css.briefing}
          dangerouslySetInnerHTML={getRaw(this.props.html)}
        ></div>
      </div>
    );
  }
}

export default Briefing;
