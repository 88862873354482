//@flow
import fluxconvert from './fluxconvert';

import MainFooter from '../lib/mainfooter/MainFooter';
// import {
//   Actions
// } from '../data/actions';
import {
  findGetParameter
} from '../lib/Helpers';
import * as React from 'react';
import ScrollToTop from '../lib/scroll-to-top';
import {
  Container
} from 'flux/utils';
import App from './app';
import Store from '../data/store'
import {
  CookiesProvider,
  // Cookies
} from 'react-cookie';

import {
  BrowserRouter as RouterContext
} from 'react-router-dom'

import {
  library
} from '@fortawesome/fontawesome-svg-core'
// import {
//   FontAwesomeIcon
// } from '@fortawesome/react-fontawesome'
// import {
//   fab
// } from '@fortawesome/free-brands-svg-icons'
import {
  faChevronCircleRight,
  // faChevronCircleDown,
  // faExclamationTriangle,
  // faPhoneVolume
} from '@fortawesome/free-solid-svg-icons'
import Logo from './logo';
import {
  ERR404
} from '../lib/404';
library.add(faChevronCircleRight, faChevronCircleRight)

class AppContainer extends React.Component<*> {

  static getStores() {
    return [Store];
  }

  static calculateState(prevState) {
    return {
      Store: Store.getState()
    };
  }


  render() {

    const at = findGetParameter('at');
    // const ats = findGetParameter('ats');


    // const cookies = new Cookies();
    // brand = cookies.get('brandID');

    const app = at ? <App /> : <div>
      <div className="app"><div className="err-notify">Did you use the correct link that was given to you?</div><ERR404 /></div><Logo /><MainFooter />
    </div>;

    return <CookiesProvider>
      <RouterContext>
        <ScrollToTop>
          {app}
        </ScrollToTop>
      </RouterContext>
    </CookiesProvider>;
  }
}
export default Container.create(fluxconvert(AppContainer));