// @flow
import * as React from 'react';
import {
  Link
} from 'react-router-dom';
import LogoImg from '../img/idd-logo-white.png';

class Logo extends React.Component < * > {
  render() {
    return <div className="footer-logo">
      <Link to="/"><img src={LogoImg} alt="logo"/></Link>
    </div>
  }
}
export default Logo