//@flow
import * as React from "react";
// import {
//   Link
// } from 'react-router-dom';
import css from "./itinerary.module.scss";
import { getPrettyTime, getPrettyDate, getPrettyDurationFromMins } from "../../lib/Helpers";
import InfoItem from "./info-item";
import { FontAwesomeIcon as FontAwesome } from "@fortawesome/react-fontawesome";
type Props = {
  performance: Object,
};
type State = { showWarning: boolean };

class Performance extends React.Component<Props, State> {
  state = { showWarning: false };

  _toggleWarning() {
    this.setState({ showWarning: !this.state.showWarning });
  }

  render() {
    const p = this.props.performance;

    const recordWarning =
      p.timeline.room.name === "Reminisce Arena" ? (
        <div className={css.recordWarning}>
          Please note: Performances in this arena may be broadcast live on DAB radio in the North West of England via our media partner "In Demand Radio". All performances are filmed for use in future
          marketing videos.
        </div>
      ) : (
        <div className={css.recordWarning}>Please note: All performances are filmed for use in future marketing videos.</div>
      );
    return (
      <div className={css.column}>
        {this.state.showWarning ? (
          <div className={css.artistwarningWrapper}>
            <div className={css.artistwarning}>
              Important: please remember that Reminisce is an "Old Skool" festival. All DJs & performers must play music released no later than 2012. The stage manager in your arena is authorised to
              end your set if you ignore this policy, and we will take it into consideration for future bookings.
            </div>
            <div className={css.artistwarningButtonWrapper}>
              <span onClick={() => this._toggleWarning()} className={css.artistwarningButton}>
                <FontAwesome icon="check" /> OK, Got it
              </span>
            </div>
          </div>
        ) : null}
        <div className={css.infoItemWrapper}>
          <InfoItem k="Artist" v={p.artists.edges.map((edge) => edge.node.name).join(" & ")} />
          <InfoItem k="Event" v={p.timeline.room.event.name} />
          <InfoItem k="Date" v={getPrettyDate(p.start)} />
          <InfoItem k="Stage Name" v={p.timeline.room.name} />
          <InfoItem k="Stage Time" v={getPrettyTime(p.start)} />
          <InfoItem k="Performance Duration" v={getPrettyDurationFromMins(p.duration)} />
          <InfoItem k="On Before You" v={p.onBefore && p.onBefore.artists.edges[0].node.name} />
          <InfoItem k="On After You" v={p.onAfter && p.onAfter.artists.edges[0].node.name} />
          {recordWarning}
        </div>
      </div>
    );
  }
}

export default Performance;
