// @flow
import React from 'react';
import css from './MainFooter.module.scss';

class MainFooter extends React.Component < * > {
  render() {
    let today = new Date();
    let year = today.getFullYear();
    return <div className={css.mainfooter}>
      <div className={css.mainfooterItemDim}>&copy; 2006-{year} In Demand Digital.</div>
    </div>
  }
}

export default MainFooter;