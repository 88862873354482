//@flow
import {
  Environment,
  RecordSource,
  Store,
} from 'relay-runtime';

import network from './network-layer'


// ENV
const source = new RecordSource();
const store = new Store(source);
const handlerProvider = null;

// Create an environment using this network:
const environment = new Environment({
  handlerProvider, // Can omit.
  network,
  store,
});

// Environment is a singleton - so can import it as needed via IMPORT
export default environment;
