// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import './sass/global_styles.module.scss';



import App from './app/app-container';
// import Dispatcher from './app/Dispatcher.js';
import {unregister} from './registerServiceWorker';

import logger from './logger';
logger.setLogDebug(true);
unregister();
const root = document.getElementById('root');
root && ReactDOM.render(<App />, root);
// registerServiceWorker();
