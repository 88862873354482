//@flow
import * as React from 'react';
import InfoItem from './info-item';
import css from "./itinerary.module.scss";
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
  formatPhonenumber,
  formatPostcode
} from '../../lib/Helpers';

type Props = {
  venue: Object,
}
class Venue extends React.Component<Props> {

  API_KEY = "AIzaSyDqrGe5JnwC3BDFx-zkSn7n8rVU77Ywueo";

  render() {
    const v = this.props.venue;
    if (!v) return null
    let map = null;
    if (v && v.latitude && v.longitude) {
      map = <iframe
        className={css.locationFrame}
        title="Location Map"
        frameBorder="0" style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${v.latitude},${v.longitude}`} allowFullScreen>
      </iframe>
    } else if (v && v.postcode) {
      map = <iframe
        className={css.locationFrame}
        title="Location Map"
        frameBorder="0" style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${this.API_KEY}&q=${v.postcode}`} allowFullScreen>
      </iframe>
    } else {
      map = <div className={css.locationFrame}>Provide a postcode or lat/long to display the map.</div>;
    }


    return <div className={css.section}>
      <div className={css.column}>
        <div className={css.infoItemWrapper}>
          <InfoItem k="Venue" v={v.name} />
          <InfoItem k="Address" v={v.address} />
          <InfoItem k="Postcode" v={formatPostcode(v.postcode)} />
          <InfoItem k="What3Words" v={v.what3words} />
          <InfoItem k="Phone" v={v.phone ? formatPhonenumber(v.phone) : "n/a"} />
          <div className={css.warning}>
            <FontAwesomeIcon icon="exclamation-triangle" /> This is the number for the venue reception - NOT a primary contact
          </div>
        </div>
      </div>
      <div className={css.columnMap}>
        {map}
      </div>
    </div>;
  }
}

export default Venue;